<template>
  <div class="map-content">
    <iframe id="iFrame" :src="url" allowfullscreen></iframe>
  </div>
</template>

<script>
import {mapState} from 'vuex'
export default {
  components: {},
  data() {
    return {
      winWidth: window.innerWidth,
      winHeight: window.innerHeight
    }
  },
  computed: {
    ...mapState({
      token: (state) => state.Login.token
    }),
    url() {
      return `https://webgis.rams.kg/?token=${this.token}`
    }
  }
}
</script>
<style lang="scss">
.map-content {
  margin: 0 0px 0;
  height: 100%;
  //overflow: hidden;

  iframe {
    width: 100%;
    height: 95vh;
    overflow: scroll;
    border: 0;
    //@include shadow-big();
  }
}
</style>
